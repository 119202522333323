const getDownloadLink = () => {
  const platform = window.navigator.platform;

  if (platform.startsWith("Win")) {
    // return "https://dwklzl6ur5wp8.cloudfront.net/windows/Jumble%20Time%20Tracker%20Setup%201.0.0.exe";
    return null;
  } else if (platform.startsWith("Mac")) {
    return "https://dwklzl6ur5wp8.cloudfront.net/mac/Jumble%20Time%20Tracker-1.0.0-arm64.dmg";
  } else {
    return null; // Default or show a message that the platform is not supported
  }
};

export default getDownloadLink;
