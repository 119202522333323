import React from "react";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import {
  MdAccessTime,
  MdShowChart,
  MdDownload,
  MdBarChart,
  MdSettings,
  MdMenu,
  MdError,
} from "react-icons/md";
import { FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import getDownloadLink from "./helpers/download";

const GlobalStyle = createGlobalStyle`
  /* Global Styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Inter', sans-serif;
    background-color: #121212;
    color: white;
    overflow-x: hidden;
  }

  html {
    scroll-behavior: smooth;
  }
`;

const NavBar = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(30, 30, 30, 0.95);
  padding: 1rem 2rem;
  z-index: 50;
`;

const NavContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    font-size: 1.25rem;
    font-weight: bold;
  }

  svg {
    font-size: 1.75rem;
    color: #bb86fc;
  }
`;

const NavLinks = styled.div`
  display: none;
  gap: 1.5rem;

  @media (min-width: 768px) {
    display: flex;
  }

  a {
    color: #bbb;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #bb86fc;
    }
  }
`;

const MobileMenuIcon = styled.div`
  display: block;
  color: #bbb;
  font-size: 1.75rem;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Button = styled.a`
  background-color: #bb86fc;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: none;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  font-size: 0.83rem;

  &:hover {
    background-color: #9b68c8;
  }

  svg {
    font-size: 1.25rem;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

const HeroSection = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

interface BackgroundProps {
  imageUrl: string;
}

const Background = styled.div<BackgroundProps>`
  position: absolute;
  inset: 0;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-size: cover;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 20;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  max-width: 800px;

  h1 {
    font-size: clamp(2rem, 5vw, 3.75rem);
    margin-bottom: 1.5rem;

    span {
      color: #bb86fc;
    }
  }

  p {
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    color: #bbb;
    margin-bottom: 2rem;
  }
`;

const FeaturesSection = styled.section`
  padding: 5rem 1rem;
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 4rem;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 0 1rem;
`;

const FeatureCard = styled.div`
  background-color: #1e1e1e;
  padding: 1.5rem;
  border-radius: 1rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-0.5rem);
  }

  .icon {
    color: #bb86fc;
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    color: #bbb;
  }
`;

const HowItWorksSection = styled.section`
  padding: 5rem 1rem;
  background-color: #181818;

  h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 4rem;
  }
`;

const StepsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;

  .icon {
    font-size: 3rem;
    color: #bb86fc;
  }

  div {
    h3 {
      margin-bottom: 0.5rem;
    }

    p {
      color: #bbb;
    }
  }
`;

const DownloadSection = styled.section`
  position: relative;
  padding: 8rem 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DownloadContent = styled.div`
  position: relative;
  z-index: 20;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: clamp(2rem, 4vw, 2.5rem);
    margin-bottom: 1.5rem;
  }

  p {
    color: #bbb;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    margin-bottom: 2rem;
  }
`;

const Footer = styled.footer`
  background-color: #1e1e1e;
  padding: 2rem 1rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const LeftFooterSection = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex: 1 1 auto;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

const CenterFooterSection = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 8rem;
  justify-content: center;

  @media (min-width: 768px) {
    flex: 2 1 auto;
    justify-content: flex-start;
  }
`;

const FooterSection = styled.div`
  h3 {
    margin-bottom: 1rem;
  }

  p {
    color: #bbb;
    margin-top: 10px;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 0.5rem;

      a {
        color: #bbb;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #bb86fc;
        }
      }
    }
  }
`;

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  a {
    color: #bbb;
    margin: 0 0.5rem;
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #bb86fc;
    }
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 2rem;

  p {
    color: #bbb;
    font-size: 0.9rem;
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  background-color: rgba(30, 30, 30, 0.95);
  width: 200px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 49;

  a {
    color: #bbb;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #bb86fc;
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const DownloadButton = ({ downloadLink }: { downloadLink: string | null }) => {
  const [showPopup, setShowPopup] = React.useState(false);

  const handleDownloadClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!downloadLink) {
      event.preventDefault();
      setShowPopup(true);
    }
  };

  return (
    <>
      <Button href={downloadLink || "#"} onClick={handleDownloadClick}>
        <MdDownload />
        Download on Mac
      </Button>
      {showPopup && (
        <>
          <PopupOverlay onClick={() => setShowPopup(false)} />
          <Popup>
            <div className="icon">
              <MdError />
            </div>
            <p>
              There was an issue with the download. Please try again later. Keep
              in mind, the app is currently Mac-only.
            </p>
            <CloseButton onClick={() => setShowPopup(false)}>Close</CloseButton>
          </Popup>
        </>
      )}
    </>
  );
};

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1c1c1c;
  padding: 1.5rem;
  border-radius: 1rem;
  z-index: 100;
  text-align: center;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.3s ease-out;

  .icon {
    font-size: 2.5rem;
    color: #ff5555;
    margin-bottom: 0.8rem;
  }

  p {
    margin-bottom: 1rem;
    color: #fff;
    font-size: 0.9rem;
    line-height: 1.4;
  }
`;

const CloseButton = styled.button`
  background-color: #ff5555;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e04444;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  backdrop-filter: blur(2px);
`;

const App = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const downloadLink = getDownloadLink();

  const features = [
    {
      icon: <MdShowChart />,
      title: "Real-time Tracking",
      description: "Monitor your application usage as it happens.",
    },
    {
      icon: <MdBarChart />,
      title: "Detailed Analytics",
      description: "Get insights into your productivity patterns.",
    },
    {
      icon: <MdSettings />,
      title: "Custom Categories",
      description: "Organize applications into meaningful groups.",
    },
  ];

  const steps = [
    {
      icon: <MdDownload />,
      title: "1. Download and Install",
      description:
        "Get TimeRival from our website and install it on your computer.",
    },
    {
      icon: <MdSettings />,
      title: "2. Set Up Preferences",
      description: "Customize settings to tailor the app to your workflow.",
    },
    {
      icon: <MdShowChart />,
      title: "3. Start Tracking",
      description: "Begin monitoring your application usage seamlessly.",
    },
    {
      icon: <MdBarChart />,
      title: "4. Review Analytics",
      description: "Analyze detailed reports to understand your productivity.",
    },
  ];

  return (
    <>
      <GlobalStyle />
      <NavBar>
        <NavContent>
          <Logo>
            <MdAccessTime />
            <span>TimeRival</span>
          </Logo>
          <NavLinks>
            <a href="#features">Features</a>
            <a href="#how-it-works">How It Works</a>
            <a href="#download">Download</a>
          </NavLinks>
          <DownloadButton downloadLink={downloadLink} />
          <MobileMenuIcon onClick={() => setMenuOpen(!menuOpen)}>
            <MdMenu />
          </MobileMenuIcon>
        </NavContent>
        {menuOpen && (
          <MobileMenu>
            <a href="#features" onClick={() => setMenuOpen(false)}>
              Features
            </a>
            <a href="#how-it-works" onClick={() => setMenuOpen(false)}>
              How it works
            </a>
            <a href="#download" onClick={() => setMenuOpen(false)}>
              Download
            </a>
          </MobileMenu>
        )}
      </NavBar>

      <HeroSection>
        <Background imageUrl="https://picsum.photos/id/26/1920/1080" />
        <Overlay />
        <HeroContent>
          <h1>
            Track Your Time, <span>Master Your Day</span>
          </h1>
          <p>
            Understand how you spend your time on your computer with our
            intelligent tracking tool.
          </p>
          <DownloadButton downloadLink={downloadLink} />
        </HeroContent>
      </HeroSection>

      <FeaturesSection id="features">
        <h2>Powerful Features</h2>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index}>
              <div className="icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </FeatureCard>
          ))}
        </FeatureGrid>
      </FeaturesSection>

      <HowItWorksSection id="how-it-works">
        <h2>How It Works</h2>
        <StepsContainer>
          {steps.map((step, index) => (
            <Step key={index}>
              <div className="icon">{step.icon}</div>
              <div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </Step>
          ))}
        </StepsContainer>
      </HowItWorksSection>

      <DownloadSection id="download">
        <Background imageUrl="https://picsum.photos/id/48/1920/1080" />
        <Overlay />
        <DownloadContent>
          <h2>Ready to Take Control of Your Time?</h2>
          <p>
            Download TimeRival now and start understanding how you spend your
            digital time.
          </p>
          <DownloadButton downloadLink={downloadLink} />
        </DownloadContent>
      </DownloadSection>

      <Footer>
        <FooterContent>
          <LeftFooterSection>
            <Logo>
              <MdAccessTime />
              <span>TimeRival</span>
            </Logo>
            <p style={{ marginTop: "10px" }}>
              Track your time, boost your productivity.
            </p>
          </LeftFooterSection>
          <CenterFooterSection>
            <FooterSection>
              <h3>Product</h3>
              <ul>
                <li>
                  <a href="#features">Features</a>
                </li>
                <li>
                  <a href="#download">Download on Mac</a>
                </li>
              </ul>
            </FooterSection>
            <FooterSection>
              <h3>Company</h3>
              <ul>
                <li>
                  <a href="#how-it-works">How It Works</a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.andrewgunderman.com"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </FooterSection>
            <FooterSection>
              <h3>Follow Us</h3>
              <SocialMedia>
                <a
                  href="https://www.instagram.com/andrewcoherence/"
                  aria-label="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://twitter.com/andrewcoherence"
                  aria-label="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://www.linkedin.com/in/andrew-gunderman/"
                  aria-label="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </SocialMedia>
            </FooterSection>
          </CenterFooterSection>
        </FooterContent>
        <FooterBottom>
          <p>© 2024 TimeRival. All rights reserved.</p>
        </FooterBottom>
      </Footer>
    </>
  );
};

export default App;
